import { useEffect } from "react";
export const SelectProjectPage = () => {

    useEffect(() => {
        const _botName = localStorage.getItem("project_name");
        console.log(_botName);
        if (_botName) {
            window.location.href = "/wallets";
        }
    }, []);

    const handleSubmit = async e => {
        const val = document.querySelector('#botSelector').value;
        if (val != "0") {
            localStorage.setItem('project_name', val);
            window.location.href = "/wallets";
        }
    };
    
    return <div>
        <section className="vh-100" style={{ backgroundColor: "#ccc" }}>
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="card shadow-2-strong" style={{ borderRadius: "0.6rem" }}>
                            <div className="card-body p-4 text-center">

                                <h3 className="mb-4">Select a project</h3>

                                <select id="botSelector" className="form-select" aria-label="Default select example">
                                    <option value="0">Select project</option>
                                    <option value="popz">popz</option>
                                    <option value="erbai">core</option>
                                </select>
                                <button className="btn btn-success btn-lg btn-block mt-4" type="submit" onClick={handleSubmit}>GO</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>;
}