import { useState } from "react";
import ReactModal from "react-modal";
import { Slider } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export const PanicActionModal = ({ setShowModal, showModal, tokenName, solanaService }) => {
    // tokenName is SOL for BUY action

    const [errorMsg, setErrorMsg] = useState();
    const [loading, setLoadingState] = useState(false);
    const [percents, setPercents] = useState(0);

    const BATCH_SIZE = 5


    const executeAction = async () => {
        setErrorMsg(null);
        if (percents === 0) {
            setErrorMsg("Please select percents");
            return;
        }
        
        // const _pecents = percents === 100 ? 99 : percents;

        setLoadingState(true);

        const wallets = JSON.parse(sessionStorage.getItem("walletsBalances"));

        var nonEmptyWallets = wallets.filter((wallet) => Math.floor(wallet.tokenBalance) > 0);

        var bundles = [];

        for (var i = 0; i < nonEmptyWallets.length; i += BATCH_SIZE) {
            const chunk = nonEmptyWallets.slice(i, i + BATCH_SIZE);
            const sellers = {};
            chunk.forEach((wallet) => {
                if (Math.floor(wallet.tokenBalance) > 0) {
                    sellers[wallet.address] = wallet.tokenBalance / 100 * percents
                }
            });
            bundles.push(sellers);
        }

        const promises = [];

        for (var bundle of bundles) {
            const promise = solanaService.sellBundleJito(bundle)
            promises.push(promise)
        }

        await Promise.all(promises);

        setLoadingState(false);
        setShowModal(false);
    }

    function closeModal() {
        setErrorMsg(null);
        setLoadingState(false);
        setShowModal(false);
    }

    async function handleSliderChange(event, number) {
        setPercents(number);
    }

    // const handleCheckboxChange = (event) => {
    //     setFastMode(event.target.checked);
    // };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (

        <ReactModal
            ariaHideApp={false}
            isOpen={showModal}
            contentLabel="Minimal Modal Example"
            style={customStyles}
        >
            <div className="container" style={{ minHeight: "200px", minWidth: "300px" }}>

                <div className="row pt-4">
                    {/* <div className="mb-3">
                        <FormControlLabel
                            control={<Checkbox name="fastMode" onChange={handleCheckboxChange} />}
                            label="Fast mode"
                        />
                    </div> */}
                    <div className="input-group mb-5">
                        Sell from all wallets. Select % amount.
                    </div>

                    <div className="mb-1">
                        <Slider
                            name="amountSlider"
                            defaultValue={0}
                            aria-labelledby="discrete-slider-small-steps"
                            step={1}
                            min={0}
                            max={100}
                            valueLabelDisplay="on"
                            onChangeCommitted={(event, number) => handleSliderChange(event, number)}
                        />
                    </div>
                    {
                        errorMsg ? <div className="mt-2" style={{ color: "red" }}>{errorMsg}</div> : <div></div>
                    }
                </div>

                {loading ?
                    <div className="container text-center mt-1 mb-1">
                        <div className="container loading-spinner" style={{ width: "8rem", height: "8rem" }}>
                            <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                                <path fill="#919191" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                    <animateTransform
                                        attributeName="transform"
                                        attributeType="XML"
                                        type="rotate"
                                        dur="1s"
                                        from="0 50 50"
                                        to="360 50 50"
                                        repeatCount="indefinite" />
                                </path>
                            </svg>
                        </div>
                        <div className="container">Sending TX. Please wait.</div>
                    </div>
                    :
                    <div>
                        <div className="row mt-4">
                            <button type="button" className="btn btn-danger bold" onClick={executeAction}>Sell {tokenName}</button>
                        </div>
                        <div className="row mt-4">
                            <button type="button" className="btn btn-outline-dark bold" onClick={closeModal}>Cancel</button>
                        </div>
                    </div>
                }


            </div>
        </ReactModal>
    )
}

