import React from "react";
import Header from "./Header";
import { SolanaService } from '../services/solana.service.ts';
import { getProjectInfo } from "./projectInfo";


export const InfoPage = () => {
    const { useState } = React;
    const { useEffect } = React;

    const [loading, setLoadingState] = useState(false);
    const [currentMcap, setCurrentMcap] = useState(0);
    const [infoData, setInfoData] = useState([]);
    const [solanaPrice, setSolanaPrice] = useState(0);



    useEffect(async () => {
        const liquidityPoolKeys = getProjectInfo(localStorage.getItem("project_name"))["liquidityPoolKeys"];
        const solanaService = new SolanaService(liquidityPoolKeys);
        const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd');
        const data = await response.json();
        const _solanaPrice = data.solana.usd;
        const _currentMcap = await solanaService.getCurrentMcap(_solanaPrice);
        const _info = await solanaService.getMMInfoData(_solanaPrice);
        setCurrentMcap(_currentMcap);
        setInfoData(_info);
        setSolanaPrice(_solanaPrice)
        setLoadingState(false);
    }, [])



    return <div>
        {<Header />}
        {
            loading
                ?
                <div className="container loading-spinner pt-5 mt-5" style={{ width: "10rem", height: "10rem" }}>
                    <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                        <path fill="#919191" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                dur="1s"
                                from="0 50 50"
                                to="360 50 50"
                                repeatCount="indefinite" />
                        </path>
                    </svg>
                </div>
                :
                <div>
                    <div className="container pt-3 pb-3">

                        <div className="container mb-2">
                            <div className="bold mb-2">Calculated for 1 SOL = {solanaPrice}$</div>
                            <div className="mb-2">Current Market Cap: ${parseInt(currentMcap / 1000)}k</div>
                            <div className="mb-2">Purchase --- New Market Cap:</div>
                            {infoData.map((entry) => {
                                return <div>{entry.solAmount} SOL  ---  ${parseInt(entry.mcap / 1000)}k</div>
                            })}
                        </div>
                    </div>
                </div>
        }
    </div>;
}